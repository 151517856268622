import React, { useState, useRef } from 'react'
import { Modal } from 'react-bootstrap';
import { MAIN_USER_URL } from '../../api/urls';
import { validateEmail, validatePassword } from '../../utils/validation';
import API from '../../utils/request'
import MessageModalMain from "../../containers/modal/messageModalMain";
import LoadingOverlay from '../loading/loadingOverlay';
import { validateUserName } from '../../functions/format/userName';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { FiEyeOff, FiEye } from "react-icons/fi";

const AddAdminModal = (props) => {
    const { showModal, setAdminModal, getAdmin } = props
    const [firstName, setFirstName] = useState("");
    const [firstNameInvalid, setFirstNameInvalid] = useState("")
    const [lastName, setLastName] = useState("");
    const [lastNameInvalid, setLastNameInvalid] = useState("")
    const [email, setEmail] = useState("");
    const [emailInvalid, setEmailInvalid] = useState("");
    const [emailValidate, setEmailValidate] = useState("")
    const [userNameValidate, setUserNameValidate] = useState("")
    const [username, setUsername] = useState("");
    const [usernameInvalid, setUsernameInvalid] = useState("")
    const [repassword, setRepassword] = useState("")
    const [password, setPassword] = useState("")
    const [rePasswordInvalid, setRePasswordInvalid] = useState("")
    const [newPasswordInvalid, setNewPasswordInvalid] = useState("")
    const [emptyField, setEmptyField] = useState("")
    const [loading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [showRepassword, setShowRepassword] = useState(false);
    const messageModalRef = useRef('rememberMe');

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!firstName) {
            setFirstNameInvalid("Please enter a first name!");
            return false
        }
        if (!lastName) {
            setLastNameInvalid("Please enter a last name!");
            return false
        }
        if (!email) {
            setEmailInvalid("Please enter an email!");
            return false;
        }
        if (email) {
            if (!validateEmail(email)) {
                setEmailValidate("Email is Invalid!")
                setLoading(false)
                return false;
            }
        }
        if (!username) {
            setUsernameInvalid("Please enter a username!");
            return false
        }
        if(username){
            if (!validateUserName(username)) {
                setUserNameValidate("Username should be 4 to 20 characters!")
                setLoading(false)
                return false;
            }
        }
        if (!password || !repassword) {
            setEmptyField("This field cannot be empty")
            return false;
        }
        else if (password !== repassword) {
            setRePasswordInvalid("Please make sure the password matches")
            return false;
        } else if (!validatePassword(password)) {
            setNewPasswordInvalid("New password should be 8 to 32 characters long, and include numbers, uppercase, and lowercase letters")
            return false;
        }
        setLoading(true)
        API.post(MAIN_USER_URL + '/admin', {
            "firstName": firstName,
            "lastName": lastName,
            "primaryEmail": email,
            "userName": username,
            "password": password,
            "roles": 'admin'
        }).then((res) => {
                if (res.status === 1) {
                    setLoading(false)
                    messageModalRef.current.showSuccess("You have successfully added a new user")
                    setFirstName("")
                    setLastName("")
                    setEmail("")
                    setUsername("")
                    setPassword("")
                    setRepassword("")
                    setAdminModal(false)
                    getAdmin()
                } else {
                    messageModalRef.current.showWarning(res.message);
                }


            }).catch((err) => {
                messageModalRef.current.showWarning(err.message);
                setLoading(false)
            })
    }
    return (
        <>
            <Modal
                show={showModal}
                onHide={() => {
                    setFirstName("")
                    firstNameInvalid("")
                    setLastName("")
                    setEmail("")
                    setUsername("")
                    setPassword("")
                    setRepassword("")
                }}
                centered
                size="lg"
            >
                <div style={{ backgroundColor: "white" }}>
                <IoIosCloseCircleOutline
                        onClick={() => {
                            setAdminModal(false)
                        }}
                        size={24}
                        color="gray"
                        style={{ float: 'right', marginTop: '10px', marginRight: '10px', cursor: 'pointer' }}
                    />
                    <div className="p-5">
                        <div className="text-center">
                            <h1 style={{ fontSize: '24px', fontWeight: 700 }}>Add Admin</h1>
                            <p style={{ fontSize: '16px', color: "#8B8B8B" }}>Please fill out all fields</p>
                        </div>
                        <div className="">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <label className="labelTextLogin col-12 col-md-3 col-sm-12">First Name</label>
                                    <div style={{ position: 'relative' }} className="col-12 col-md-9 col-sm-12">
                                        <input
                                            type="text"
                                            className={`form-control ${firstNameInvalid && !firstName ? "is-invalid" : ""}`}
                                            style={{
                                                height: "50px",
                                            }}
                                            value={firstName}
                                            onChange={(e) => {
                                                setFirstName(e.target.value);
                                            }}
                                        />
                                        <div className="invalid-feedback">{firstNameInvalid && !firstName ? firstNameInvalid : ""}</div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="labelTextLogin col-12 col-md-3 col-sm-12">Last Name</label>
                                    <div style={{ position: 'relative' }} className="col-12 col-md-9 col-sm-12">
                                        <input
                                            type="text"
                                            className={`form-control ${lastNameInvalid && !lastName ? "is-invalid" : ""}`}
                                            style={{
                                                height: "50px",
                                            }}
                                            value={lastName}
                                            onChange={(e) => {
                                                setLastName(e.target.value);
                                            }}
                                        />
                                        <div className="invalid-feedback">{lastNameInvalid && !lastName ? lastNameInvalid : ""}</div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="labelTextLogin col-12 col-md-3 col-sm-12">Email</label>
                                    <div style={{ position: 'relative' }} className="col-12 col-md-9 col-sm-12">
                                        <input
                                            className={`form-control ${emailInvalid && !email || emailValidate ? "is-invalid" : ""}`}
                                            style={{
                                                height: "50px",
                                            }}
                                            value={email}
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                                setEmailValidate("")
                                            }}
                                        />
                                        <div className="invalid-feedback">{emailInvalid && !email ? emailInvalid : emailValidate ? emailValidate : ""}</div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="labelTextLogin col-12 col-md-3 col-sm-12">UserName</label>
                                    <div style={{ position: 'relative' }} className="col-12 col-md-9 col-sm-12">
                                        <input
                                            type="text"
                                            className={`form-control ${usernameInvalid && !username || userNameValidate ? "is-invalid" : ""}`}
                                            style={{
                                                height: "50px",
                                            }}
                                            value={username}
                                            onChange={(e) => {
                                                setUsername(e.target.value);
                                                setUserNameValidate("");
                                            }}
                                        />
                                        <div className="invalid-feedback">{usernameInvalid && !username ?  usernameInvalid : userNameValidate ? userNameValidate : ""}</div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="labelTextLogin col-12 col-md-3 col-sm-12">Password</label>
                                    <div style={{ position: 'relative' }} className="col-12 col-md-9 col-sm-12">
                                        <div className="d-flex align-items-center">
                                            <input
                                                type={showPassword ? "text" : "password"}
                                                autocomplete="new-password"
                                                className={`form-control newPassword hideIcon ${(emptyField && !password) || newPasswordInvalid ? "is-invalid" : ""}`}
                                                style={{
                                                    height: "50px",
                                                }}
                                                value={password}
                                                onChange={(e) => {
                                                    setPassword(e.target.value);
                                                    setEmptyField("")
                                                    setNewPasswordInvalid("")
                                                }}
                                            />
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    cursor: "pointer",
                                                    right: "23px"
                                                }}
                                                onClick={() => setShowPassword(!showPassword)}
                                            >
                                                {showPassword ? (
                                                    <FiEye ></FiEye>
                                                ) : (
                                                    <FiEyeOff ></FiEyeOff>
                                                )}
                                            </span>
                                        </div>
                                        <div className="invalid-feedback" style={{ display: emptyField && !password || newPasswordInvalid ? 'block' : 'none' }}>{emptyField && !password ? emptyField : newPasswordInvalid ? newPasswordInvalid : ""}</div>
                                        {
                                            newPasswordInvalid ? null : <small className="" style={{ fontSize: "14px", color: '#949494', fontWeight: 400 }}>New password should be 8 to 32 characters long, and include numbers, uppercase, and lowercase letters</small>
                                        }
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="labelTextLogin col-12 col-md-3 col-sm-12">Confirm Password</label>
                                    <div style={{ position: 'relative' }} className="col-12 col-md-9 col-sm-12">
                                    <div className="d-flex align-items-center">
                                        <input
                                            type={showRepassword ? "text" : "password"}
                                            autocomplete="new-password"
                                            className={`form-control newPassword hideIcon ${(emptyField && !repassword) || rePasswordInvalid ? "is-invalid" : ""}`}
                                            style={{
                                                height: "50px",
                                            }}
                                            value={repassword}
                                            onChange={(e) => {
                                                setRepassword(e.target.value);
                                                setEmptyField("")
                                                setRePasswordInvalid("")
                                            }}
                                        />
                                        <span
                                            style={{
                                                position: "absolute",
                                                    cursor: "pointer",
                                                    right: "23px"
                                            }}
                                            onClick={() => setShowRepassword(!showRepassword)}
                                        >
                                            {showRepassword ? (
                                                <FiEye ></FiEye>
                                            ) : (
                                                <FiEyeOff ></FiEyeOff>
                                            )}
                                        </span>
                                        </div>
                                        <div className="invalid-feedback" style={{ display: emptyField && !repassword || rePasswordInvalid ? 'block' : 'none' }}>{emptyField && !repassword ? emptyField : rePasswordInvalid ? rePasswordInvalid : ""}</div>
                                    </div>
                                </div>
                                <button
                                    className="button_auth button_body"
                                    style={{ marginTop: "32px" }}
                                    onClick={() => {
                                        handleSubmit()
                                    }}
                                >
                                    <p
                                        style={{
                                            fontWeight: "800",
                                            fontSize: "16px",
                                            marginBottom: "0",
                                            color: "white",
                                        }}
                                    >
                                        Submit
                                    </p>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal>
            {loading ? <LoadingOverlay /> : null}
            <MessageModalMain textCentered ref={messageModalRef} />
        </>
    )
}

export default AddAdminModal;