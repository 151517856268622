import React from 'react';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx-js-style';
import moment from 'moment';
import { COMPAIGN_LIST_URL } from '../api/urls';
import API from "../utils/request";
import { FeedESTDateToString, formatDateAPI1 } from "../functions/format/date";
import { dataRequestTypeValue, statusCapitalizeFirst } from '../containers/FixedData/fixedData';
import { capitalizeFirst, capitalizeFirstNew } from '../functions/stringFormat';

const ExportToExcel = ({props}) => {
    const {
        searchStatus, search, 
        submissionValue, requestType, 
        beforeDate, afterDate,
        fromDate, toDate, sortField,
        sort, dataDontationHistory,
        arrStatus, 
    }= props

    const currentUrl = window.location.origin;
    const currentDate = new Date()

    dataDontationHistory.forEach((it) => {
        if (it.isCheck) {
            arrStatus.push(it.value);
        }
    });
    
    const fetchUserFormList = () => {
        var params = {
            searchParams: {
                name: `${searchStatus === "Name" ? search : ""}`,
                email: `${searchStatus === "Email" ? search : ""}`,
                department: "",
                title: `${searchStatus === "Title" ? search : ""}`,
                status: arrStatus.length > 0 ? arrStatus : "",
                submissionDate: `${submissionValue ? submissionValue.toLowerCase() : ""
                    }`,
                fromDate: `${fromDate
                    ? formatDateAPI1(fromDate)
                    : afterDate
                        ? formatDateAPI1(afterDate)
                        : ""
                    }`,
                toDate: `${toDate
                    ? formatDateAPI1(toDate)
                    : beforeDate
                        ? formatDateAPI1(beforeDate)
                        : ""
                    }`,
                requestType : requestType ? dataRequestTypeValue(requestType) :"",
            },

            sorts: {
                [sortField]: sort
            }
        };
        API.post(COMPAIGN_LIST_URL, params)
            .then((res) => {
                if (res.status === 200) {
                    exportToExcels(res.data)
                }
            })
            .catch((err) => {
                console.log("Error", err)
            });
    };
    const exportToExcel = () => {
        fetchUserFormList();
    }
    
    const exportToExcels = (formData) => {

        let displayHeaders = [];
        // Get the headers dynamically from the first item
        const headers = []
        headers.push("Date");
        headers.push("Name");
        headers.push("Email");
        headers.push("Title");
        headers.push("Request Type");
        headers.push("JIRA#");
        headers.push("Progress");
        headers.push("Link");
        headers.forEach((label) => {
            displayHeaders.push(label);
        })

        const transformedData = formData && formData.map((item) => {
            item["Date"] = FeedESTDateToString(item && item.submitDate)
            item["Name"] = item ? `${capitalizeFirst(item.firstName )|| ""} ${capitalizeFirst(item.lastName) || ""}`.trim() : "";
            item["Email"] = item && item.email
            item["Request Type"] = item && capitalizeFirstNew(item.requestType)
            item["Title"] = item && item.requestType === "technical-ticket" ? item &&item.summaryIssue : item && item.title
            item["JIRA#"] = item && item.jiraTask && item.jiraTask.key ? item.jiraTask.key : ""
            item["JIRALink"] = item.jiraTask && item.jiraTask.link ? item.jiraTask.link : "";
            item["Progress"] = item && statusCapitalizeFirst(item.status)
            item["Link"] = `${currentUrl}/viewFormIntake?cam=${item && item._id}`
            return {...item};
        });

        // Convert the transformed data into an array of arrays for XLSX
        const wsData = [
            [],
            displayHeaders,
            ...transformedData.map(row => headers.map(header => {
                if (header === "Link") {
                    return { 
                        f: `HYPERLINK("${row[header]}", "${row["Title"]}")`, 
                        s: {
                            font: { color: { rgb: "0000FF" }, underline: true },
                        },
                    };
                }
                if (header === "JIRA#") {
                    return { 
                        f: `HYPERLINK("${row["JIRALink"]}", "${row["JIRA#"]}")`, 
                        s: {
                            font: { color: { rgb: "0000FF" }, underline: true },
                        },
                    };
                }
                return row[header]
            }))
        ];

        // Create a new workbook and ws
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(wsData);

        // Define styles for header cells
        const headerCellStyle = {
            font: { bold: true },
            alignment: { vertical: 'middle', horizontal: 'center' },
            border: {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            }
        };

        // Apply styles to header cells
        displayHeaders.forEach((header, index) => {
            const cellAddress = XLSX.utils.encode_cell({ r: 1, c: index });
            if (!ws[cellAddress]) ws[cellAddress] = {};
            ws[cellAddress].s = headerCellStyle;
        });

        // Define column widths
        const wscols = new Array(displayHeaders.length).fill({ wch: 25 });
        ws['!cols'] = wscols;

        // Append ws to workbook
        XLSX.utils.book_append_sheet(wb, ws, "Campaign Intake Form");

        // Write the workbook and save as an Excel file
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${"CampaignIntakeForm_"+ moment(currentDate).format("MM-DD-YYYY")}.xlsx`);
    };

    return (
        <button
        onClick={exportToExcel}
            style={{
                backgroundColor: "#FC1921",
                height: "50px",
                width: "87px",
                color:"white",
                border: "1px solid rgb(252, 25, 33)",
                borderRadius: "30px",
                fontWeight: 600
            }}
        >
            Export
        </button>
    );
};

export default ExportToExcel;