import React from 'react';
import { WARNIN_MESSAGE } from '../api/urls';
import API from '../utils/request'
import { useSearchParams } from "react-router-dom";
import '../styles/reveivedApproval.css'

const ReceivedApproval = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { warningQA, fetchViewByID, getCampaignId, dataNew, fetchViewByIDs } = props

    const warningMessage = () => {
        API.post(WARNIN_MESSAGE, {
            campaignId: searchParams.get('cam'),
            isHideWarning: 'yes',
        }).then((res) => {
            if (res.status === 1) {
                fetchViewByIDs(getCampaignId)
                return false;
            }
        }).catch((err) => {
            console.log(err)
        })
    }
    if (warningQA && warningQA.isHideWarning !=='yes') {
        return (
            <div className="container mb-4 p-0">
                <div className={`${dataNew && dataNew.isVersion === "v2" ? "col-lg-12 col-md-12" : "col-lg-6 col-sm-12"}`}>
                    <p className="size14700" style={{ color: '#FC1921', cursor: 'pointer', marginLeft: '494px' }} onClick={() => { warningMessage() }}>X Remove Warning</p>
                </div>
                <div className={`${dataNew && dataNew.isVersion === "v2" ? "bgWarning col-lg-12 col-sm-12" : "bgWarning col-lg-6 col-sm-12"}`}>
                    <p className='size14700'>Warning! Requester has <span style={{ color: 'red' }}>not received approval</span> for the following:</p>
                    <ul>
                        {
                            warningQA && warningQA.campaignIdea && warningQA.campaignIdea.map((led) => {
                                if (led.isCheck === 'no') {
                                    return <li>Has the campaign idea and proposed illustration been approved by the marketing team and legal?</li>
                                }
                            })
                        }
                        {
                            warningQA && warningQA.campaignOffer && warningQA.campaignOffer.map((cao) => {
                                if (cao.isCheck === 'no') {
                                    return <li>Has the campaign offer/budget been approved?</li>
                                }
                            })
                        }
                        {
                            warningQA && warningQA.legalDeclaimer && warningQA.legalDeclaimer.map((cai) => {
                                if (cai.isCheck === 'no') {
                                    return <li>Is there a required legal disclaimer?</li>
                                }
                            })
                        }
                    </ul>
                </div>
            </div>
        )
    }
}

export default ReceivedApproval;