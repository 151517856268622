import React from 'react'
import API from '../utils/request'
import { COMPAIGN_COMMENT } from "../api/urls";
import FieldComment from './FieldComment';

const adminCommentFields = {
    "adminAdditionalComment": "Additional Comments",
    "journeyGoalConfiguration": "Journey Goal Configuration",
    "journeyAudienceEntryCriteria": "Journey Audience Entry Criteria",
    "dataPointsHealthCloud": "Data Points/Health Cloud Requirements",
    "reEnterJourneyRequirements": "Re-enter Journey Requirements",
    "sFMCFolder": "SFMC Folder",
    "sMSPushActivitiesSettings": "SMS/Push Activities Settings",
}

const AdminComment = (props) => {

    const { compaignData, getCampaignId, fetchViewByID, fetchViewByIDs } = props

    const handleSaveComment = (keyId, value) => {

        var params = {
            "campaignId": getCampaignId,
            "adminAdditionalComment": compaignData ? compaignData.adminAdditionalComment : '',
            "journeyGoalConfiguration": compaignData ? compaignData.journeyGoalConfiguration : '',
            "journeyAudienceEntryCriteria": compaignData ? compaignData.journeyAudienceEntryCriteria : '',
            "dataPointsHealthCloud": compaignData ? compaignData.dataPointsHealthCloud : '',
            "reEnterJourneyRequirements": compaignData ? compaignData.reEnterJourneyRequirements : '',
            "sFMCFolder": compaignData ? compaignData.sFMCFolder : '',
            "sMSPushActivitiesSettings": compaignData ? compaignData.sMSPushActivitiesSettings : '',
        }

        Object.keys(params).forEach(key => {
            if (key === keyId) {
                params[key] = value;
            }

        });
        API.post(COMPAIGN_COMMENT, params)
            .then((res) => {
                fetchViewByID(getCampaignId);
                fetchViewByIDs(getCampaignId)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    return (
        <div className="col-12 col-lg-6 col-sm-12 admin-comments">
            <p className="titleStakeholder" style={{ marginBottom: '56px' }}>Admin Comments</p>
            <div>
                {
                    Object.keys(adminCommentFields).map((key) => {
                        return <FieldComment key={key} keyId={key} title={adminCommentFields[key]} fieldValue={compaignData ? compaignData[key] : ''} callBackFunction={handleSaveComment} />
                    })
                }
            </div>
        </div>
    )
}

export default AdminComment