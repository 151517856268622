export const dataSubmission = [
  {
    name: "Before",
  },
  {
    name: "After",
  },
  {
    name: "Between",
  },
];

export const dataSearch = [
  {
    name: "Name",
  },
  {
    name: "Email",
  },
  {
    name: "Title",
  },
];

export const dataRequestType = [
  {
    name: "Campaign Request",
    value: "campaign-requests"
  },
  {
    name: "Technical Ticket",
    value: "technical-ticket"
  },
];

export const progressStatus = [
  {
    name: "Pending",
    value: "pending",
    isCheck: false,
  },
  {
    name: "In Review",
    value: "in-review",
    isCheck: false,
  },
  {
    name: "Approved",
    value: "approved"
  },
  {
    name: "In Progress",
    value: "in-progress",
    isCheck: false,
  },
  {
    name: "Campaign Live",
    value: "campaign-live",
    isCheck: false,
  },
  {
    name: "Completed",
    value: "completed",
    isCheck: false,
  },
  {
    name: "Archive",
    value: "archive",
    isCheck: false,
  },
  {
    name: "Request Denied",
    value: "request-denied",
    isCheck: false,
  },
  {
    name: "Request Cancelled",
    value: "request-cancelled",
    isCheck: false,
  }
];

export const bgCheck = (type) => {
  switch (type) {
    case "completed":
      return "#E1FFE6";
    case "campaign-live":
      return "#E1FFE6";
    case "in-progress":
      return "#FFF8D6";
    case "approved":
      return "#FFF8D6";
    case "archive":
      return "#D1F5F4";
    case "request-denied":
      return "#FFCCCB";
    case "request-cancelled":
        return "#FFCCCB";
    default:
      return "#F1F1F1";
  }
};

export const statusCapitalizeFirst = (type) => {
  switch (type) {
    case "pending":
      return "Pending";
    case "in-review":
      return "In Review";
    case "approved":
      return "Approved";
    case "in-progress":
      return "In Progress";
    case "campaign-live":
      return "Campaign Live";
    case "completed":
      return "Completed";
    case "archive":
      return "Archive";
    case "request-denied":
      return "Request Denied";
    case "request-cancelled":
      return "Request Cancelled";
  }
};


export const dataRequestTypeValue = (type) => {
  switch (type) {
    case "Campaign Request":
      return "campaign-requests";
    case "Technical Ticket":
      return "technical-ticket";
  }
};

export const dataRequestTypeList = (type) => {
  switch (type) {
    case "campaign-requests":
      return "Campaign Request";
    case "technical-ticket":
      return "Technical Ticket";
    default:
      return ""
  }
};

export const checkTitle = (campaign) => {
  let key = campaign && campaign.requestType ? campaign.requestType : ""
  switch (key) {
      case `technical-ticket`:
          return campaign && campaign.summaryIssue ? campaign.summaryIssue : ""
          break;
  
      default:
          return campaign && campaign.title ? campaign.title : ""
          break;
  }
}