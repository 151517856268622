import React, { useState, useRef, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import { JIRA_URL } from '../../api/urls';
import API from '../../utils/request'
import MessageModalMain from "./messageModalMain";
import LoadingOverlay from '../loading/loadingOverlay';
import { IoIosCloseCircleOutline } from 'react-icons/io';

const JireModal = (props) => {
    const { showModal, setShowModal, fetchUserFormList, campaignId, jiraData, setJiraData, fetchUserFormListNew } = props
    const [jira, setJira] = useState("");
    const [jiraInvalid, setJiraInvalid] = useState("")
    const [jiraUrl, setJiraUrl] = useState("");
    const [jiraUrlInvalid, setJiraUrlInvalid] = useState("")
    const [loading, setLoading] = useState(false)
    const [isSubmit, setIsSubmit] = useState(false);
    const messageModalRef = useRef('rememberMe');

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmit(true);
        let isError = false
        if (jiraUrl && !jira && jiraData.key || !jiraData.key && !jira) {
            setJiraInvalid("Please enter a jira!");
            isError = true
        }
        if (jira && !jiraUrl && jiraData.key || !jiraUrl && !jiraData.key) {
            setJiraUrlInvalid("Please enter a jira URL!");
            isError = true
        }
        if (isError) {
            return false
        }
        setLoading(true)
        API.post(JIRA_URL + '', {
            campaignId: campaignId,
            jiraTask: {
                key: jira,
                link: jiraUrl
            }
        }).then((res) => {
            if (res.status === 1) {
                setLoading(false)
                setIsSubmit(false);
                messageModalRef.current.showSuccess(`${jiraData.key ? "You have successfully updated JIRA ticket link." : "You have successfully added JIRA ticket link."}`)
                setJira("")
                setJiraUrl("")
                setShowModal(false)
                setJiraData({ jiraTask: {} })
                fetchUserFormList()
                fetchUserFormListNew && fetchUserFormListNew()
            } else {
                messageModalRef.current.showWarning(res.message);
            }


        }).catch((err) => {
            messageModalRef.current.showWarning(err.message);
            setLoading(false)
        })
    }

    const addNewValue = async (data) => {
        setJira(data.key)
        setJiraUrl(data.link)
    }

    useEffect(() => {
        if (jiraData.key) {
            addNewValue(jiraData)
        }
    }, [jiraData.key])

    return (
        <>
            <Modal
                show={showModal}
                onHide={() => {
                    setJira("")
                    setJiraUrl("")
                    setIsSubmit(false)
                    setJiraData({ jiraTask: {} })
                }}
                centered
                size="lg"
            >
                <div style={{ backgroundColor: "white" }}>
                    <IoIosCloseCircleOutline
                        onClick={() => {
                            setShowModal(false)
                            setJiraData({ jiraTask: {} })
                            setJira("")
                            setIsSubmit(false)
                            setJiraUrl("")
                        }}
                        size={24}
                        color="gray"
                        style={{ float: 'right', marginTop: '10px', marginRight: '10px', cursor: 'pointer' }}
                    />
                    <div className="p-5">
                        <div className="text-center">
                            <h1 style={{ fontSize: '24px', fontWeight: 700, marginBottom: "25px" }}>{jiraData.key ? "Update Jira" : "Add JIRA"}</h1>
                        </div>
                        <div className="">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <label className="labelTextLogin col-12 col-md-3 col-sm-12">Jira#</label>
                                    <div style={{ position: 'relative' }} className="col-12 col-md-9 col-sm-12">
                                        <input
                                            type="text"
                                            className={`form-control ${isSubmit && jiraUrl && !jira && jiraData.key || !jiraData.key && isSubmit && !jira ? "is-invalid" : ""}`}
                                            style={{
                                                height: "50px",
                                            }}
                                            value={jira}
                                            onChange={(e) => {
                                                setJira(e.target.value);
                                            }}
                                        />
                                        <div className="invalid-feedback">{isSubmit && jiraUrl && !jira && jiraData.key || !jiraData.key && isSubmit && !jira ? jiraInvalid : ""}</div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="labelTextLogin col-12 col-md-3 col-sm-12">URL</label>
                                    <div style={{ position: 'relative' }} className="col-12 col-md-9 col-sm-12">
                                        <input
                                            type="text"
                                            className={`form-control ${isSubmit && jira && !jiraUrl && jiraData.key || isSubmit && !jiraUrl && !jiraData.key ? "is-invalid" : ""}`}
                                            style={{
                                                height: "50px",
                                            }}
                                            value={jiraUrl}
                                            onChange={(e) => {
                                                setJiraUrl(e.target.value);
                                            }}
                                        />
                                        <div className="invalid-feedback">{isSubmit && jira && !jiraUrl && jiraData.key || isSubmit && !jiraUrl && !jiraData.key ? jiraUrlInvalid : ""}</div>
                                    </div>
                                </div>

                                <button
                                    className="button_auth button_body"
                                    style={{ marginTop: "32px" }}
                                    onClick={() => {
                                        handleSubmit()
                                    }}
                                >
                                    <p
                                        style={{
                                            fontWeight: "800",
                                            fontSize: "16px",
                                            marginBottom: "0",
                                            color: "white",
                                        }}
                                    >
                                        {jiraData.key ? "Update" : "Submit"}
                                    </p>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal>
            {loading ? <LoadingOverlay /> : null}
            <MessageModalMain textCentered ref={messageModalRef} />
        </>
    )
}

export default JireModal;